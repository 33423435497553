@import "../../styles/shared.scss";

.error {
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: left;

  @media screen and (min-width: 768px) {
    background-image: url("../../public/svg/errorBg.svg");
    background-position: calc(50% + 50px) -150px;
    background-size: 1000px 800px;
    background-repeat: repeat-y;
  }
  @media screen and (min-width: 1000px) {
    background-size: auto;
  }
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.textWrapper {
  margin-left: 6%;
}

.titleWrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.text {
  font-weight: 700;
  font-size: 96px;
  letter-spacing: 0.3px;
  color: $color-primary-dark;
  @media screen and (max-width: 1024px) {
    font-size: 60px;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and(max-width: 425px) {
    font-size: 30px;
  }
}

.ball {
  font-size: 50px;

  @media screen and (max-width: 1024px) {
    font-size: 42px;
  }
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
  @media screen and (max-width: 425px) {
    font-size: 20px;
  }
}

.info {
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.3px;
  color: $color-primary-dark;

  @media screen and (max-width: 1024px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.info span {
  font-size: 34px;

  @media screen and (max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media screen and (max-width: 425px) {
    font-size: 20px;
    line-height: 26px;
  }
}
