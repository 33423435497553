@import "../node_modules/modern-normalize/modern-normalize.css";
@import "./shared.scss";

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), local("Mulish-Regular"),
    url("../public/fonts/Mulish-Regular.woff2"),
    url("../public/fonts/Mulish-Regular.woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), local("Mulish-SemiBold"),
    url("../public/fonts/Mulish-SemiBold.woff2"),
    url("../public/fonts/Mulish-SemiBold.woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), local("Mulish-Bold"),
    url("../public/fonts/Mulish-Bold.woff2"),
    url("../public/fonts/Mulish-Bold.woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), local("Mulish-ExtraBold"),
    url("../public/fonts/Mulish-ExtraBold.woff2"),
    url("../public/fonts/Mulish-ExtraBold.woff");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), local("Montserrat-Regular"),
    url("../public/fonts/Montserrat-Regular.ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), local("Montserrat-Medium"),
    url("../public/fonts/Montserrat-Medium.ttf");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

html,
body,
#__next {
  height: 100%;
}

body {
  background-color: $color-secondary-yellowish;
  color: $color-primary-dark;
  font-family: "Mulish", sans-serif;
}

img {
  display: block;
  max-width: 100%;
  height: 100%;
}

button:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
