.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  flex: 0 0 auto;
}
.content {
  --header-height: 54px;
  flex: 1 1 100%;
  margin-top: var(--header-height);
  @media screen and (min-width: 426px) {
    --header-height: 171px;
  }
  @media screen and (min-width: 494px) {
    --header-height: 132px;
  }
  @media screen and (min-width: 821px) {
    --header-height: 54px;
  }
  @media screen and (min-width: 1024px) {
    --header-height: 64px;
  }
}
.footer {
  flex: 0 0 auto;
}