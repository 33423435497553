@import 'styles/colors';

.wrapper {
  position: relative;
  font-size: 14px;
  color: $color-primary-dark;
  max-width: 356px;
}
.form {
  width: 100%;
}
.errorIcon {
  position: absolute;
  top:12px;
  right: 18px;
}
.formErrorIcon {
  position: absolute;
  top:30px;
  right: 18px;
}
.inputLabel {
  display: block;
  margin-bottom: 7px;
  font-weight: 500;
}
.smallLabel {
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $color-primary-dark;
  margin-bottom: 5px;
}
.errorMsg {
  font-size: 12px;
  line-height: 15px;
  padding-top: 2px;
  color: $color-form-red;
}
.customInput {
  display: block;
  width: 100%;
  min-width: 286px;
  height: 42px;
  padding: 11px 13.5px 12px 13.5px;
  font-weight: 400;
  background-color: $color-primary-light;
  border: 1px solid $color-secondary-mediumGrey;
  border-radius: 3px;

  &::placeholder {
    color: $color-secondary-darkGrey;
  }

  &:focus {
    outline: none;
    border: 1px solid $color-form-blue;

    &::placeholder {
      color: $color-primary-dark;
    }
  }
}
.input {
  height: 42px;
}
.textarea {
  height: 237px;
  resize: none;
}
.small {
  height: 64px;
}
.inputError {
  border: 1px solid $color-form-red;
  padding: 11px 45px 12px 13.5px;

  &:focus {
    outline: none;
    border: 1px solid $color-form-red;
  }

  &::placeholder {
    color: $color-primary-dark;
  }
}