@import "../../styles/shared.scss";

.header {
  position: fixed;
  width: 100%;
  background-color: $color-primary-dark;

  z-index: 6;
}

.nav {
  position: relative;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 18px;

  @media screen and (min-width: 821px) and (max-width: 1023px) {
    max-width: 1023px;
    padding-left: 18px;
  }

  @media screen and (min-width: 426px) and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
    padding-left: 18px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 425px) {
    justify-content: center;
    max-width: 425px;
    padding-left: 18px;
  }
}

.btnMenu {
  display: none;
  @extend %button;

  @media screen and (max-width: 425px) {
    position: absolute;
    top: 18px;
    left: 18px;
    display: block;
    width: 34px;
    height: 23px;
  }
}

.logWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 426px) and (max-width: 823px) {
    flex-direction: row;
  }

  @media screen and (max-width: 425px) {
    flex-direction: row-reverse;
  }
}

.navLogo {
  margin-right: 30px;
  margin-left: 10px;
  padding-top: 23px;
  padding-bottom: 23px;

  @media screen and (min-width: 426px) and (max-width: 820px) {
    margin-right: 0;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 425px) {
    margin-right: 15;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.unbroken {
  width: 100px;
  height: 70px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;

  @media screen and (min-width: 426px) and (max-width: 823px) {
    height: 40px;
  }
}

.backdrop {
  width: 100%;

  @media screen and (max-width: 425px) {
    height: 100vh;
    position: absolute;
    top: 54px;
    left: 0px;
    background-color: $color-modal-darkened;
    opacity: 0;

    pointer-events: none;
    transition: opacity 300ms;

    &.isOpen {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $color-primary-dark;

  @media screen and (min-width: 426px) and (max-width: 820px) {
    flex-direction: column;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    width: 189px;
    padding-top: 21px;
    padding-left: 18px;
    padding-right: 26px;
    padding-bottom: 24px;

    transform: translateX(-189px);
    transition: all 300ms;

    .backdrop.isOpen & {
      transform: translateX(0);
    }
  }
}

.navList {
  @extend %list;

  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 454px) and (max-width: 820px) {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 426px) and (max-width: 453px) {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    flex-direction: column;
  }
}

.navItem {
  margin-right: 47px;
  color: $color-primary-light;

  transition: color 200ms;

  &:first-child {
    margin-right: 39px;
  }

  &:nth-child(1).active {
    color: $color-accent-blue;
  }

  &:nth-child(1):hover {
    color: $color-accent-blue;
  }

  &:nth-child(2).active {
    color: $color-accent-green;
  }

  &:nth-child(2):hover {
    color: $color-accent-green;
  }

  &:nth-child(3):hover {
    color: $color-accent-orange;
  }

  &:nth-child(4):hover {
    color: $color-accent-yellow;
  }

  &:nth-child(5):hover {
    color: $color-accent-red;
  }

  &:nth-child(6):hover {
    color: $color-accent-yellow;
  }

  @media screen and (min-width: 821px) and (max-width: 1023px) {
    &:first-child {
      margin-right: 20px;
    }
    margin-right: 20px;
  }

  @media screen and (min-width: 426px) and (max-width: 820px) {
    &:first-child {
      margin-right: 17px;
    }

    &:last-child {
      margin-right: 0;
    }

    margin-right: 17px;
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 24px;
    margin-right: 0;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 39px;
    }
  }
}

.navLink {
  display: inline-block;
  padding-top: 23px;
  padding-bottom: 23px;

  font-size: 16px;
  line-height: 1.125;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media screen and (min-width: 821px) and (max-width: 1023px) {
    padding-top: 19px;
    padding-bottom: 18px;
    font-size: 14px;
    line-height: 1.258;
  }

  @media screen and (min-width: 426px) and (max-width: 820px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 1.258;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 14px;
    line-height: 1.258;
  }
}

.navLinkLatin {
  display: inline-block;
  padding-top: 23px;
  padding-bottom: 23px;

  font-size: 16px;
  line-height: 1.125;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media screen and (min-width: 821px) and (max-width: 1023px) {
    padding-top: 19px;
    padding-bottom: 18px;
    font-size: 14px;
    line-height: 1.258;
  }

  @media screen and (min-width: 426px) and (max-width: 820px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 1.258;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 14px;
    line-height: 1.258;
  }
}
