@import "../../styles/shared.scss";

.wrapper {
  position: relative;
}

.button {
  position: relative;
  background-color: $color-primary-light;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.2);
  }
}

.requests {
  position: absolute;
  top: -4px;
  right: 0;
}

.menu {
  position: absolute;
  padding: 16px 12px;
  bottom: -240px;
  right: 0;
  opacity: 0;
  width: 180px;
  display: flex;
  flex-direction: column;
  color: $color-primary-light;
  background-color: $color-primary-dark;
  transition: 0.3s;
  pointer-events: none;
}

.isMenuOpen {
  opacity: 1;
  pointer-events: auto;
}

.link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  color: $color-primary-light;
  transition: 0.3s;

  &:hover {
    transform: translateX(10px);
  }
}

.link:last-child {
  margin-bottom: 0;
}

.amount {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  width: 15px;
  height: 15px;
  background-color: $color-accent-green;
  border-radius: 50%;
  font-weight: 400;
  font-size: 9px;
  line-height: 3px;
  color: $color-primary-light;
}
