@import "styles/colors";
.wrapper {
  display: flex;
  position: relative;
  background: $color-secondary-yellowish;
}
.contentWrapper {
  flex: 1 1 100%;
  padding: 71px 136px;
}
.heading {
  font-size: 24px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: $color-primary-dark;
  padding-bottom: 6px;
}
.info {
  padding-right: 80px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: $color-secondary-darkGrey;
  padding-bottom: 6px;
}
.form {
  position: relative;
  z-index: 2;
}
.formGroup {
  margin-top: 18px;
}
.buttonWrapper {
  text-align: center;
}
.submit {
  margin-top: 36px;
}
.imageWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

@media screen and (max-width: 950px) {
  .wrapper {
    width: 100%;
  }
  .contentWrapper {
    padding: 36px 18px;
  }
  .imageWrapper {
    display: none;
  }
  .heading {
    font-size: 18px;
  }
  .info {
    padding: 0;
    font-size: 16px;
  }
}
