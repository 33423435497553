@import "styles/colors";
.button {
  border: none;
  border-radius: 3px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.75px;
  color: $color-primary-light;
  cursor: pointer;
  font-style: normal;
  display: inline-block;
  transition: all 200ms;
}
.button:disabled {
  cursor: default;
}
.loading {
  pointer-events: none;
}
.mobile {
  padding: 12px 20px;
}
.small {
  padding: 12px 10px;
  font-size: 12px;
  letter-spacing: 0.65px;
}
.medium-dense {
  padding: 10px 11px;
  font-size: 12px;
  letter-spacing: 0.75px;
}
.medium {
  padding: 12px 18px;
  font-size: 14px;
  letter-spacing: 0.3px;
}
.large {
  padding: 10px 29px;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.enormous {
  padding: 10px 45px;
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
}
.orange {
  background: $color-accent-orange;
  &:hover {
    background: $color-hover-orange;
  }
  &:disabled {
    background: $color-accent-orange;
    opacity: 50%;
  }
}
.green {
  background: $color-accent-green;
  &:hover {
    background: $color-hover-green;
  }
  &:disabled {
    background: $color-accent-green;
    opacity: 50%;
  }
}
.blue {
  background: $color-accent-blue;
  &:hover {
    background: $color-hover-blue;
  }
  &:disabled {
    background: $color-accent-blue;
    opacity: 50%;
  }
}
.lilac {
  background: $color-accent-purple;
  &:hover {
    background: $color-hover-purple;
  }
  &:disabled {
    background: $color-accent-purple;
    opacity: 50%;
  }
}
.black {
  background: $color-primary-dark;
  border: 1px solid $color-primary-dark;
  &:hover {
    background: $color-primary-light;
    color: $color-primary-dark;
  }
  &:disabled {
    background: $color-secondary-lightGrey;
  }
}
.white {
  background: $color-primary-light;
  color: $color-primary-dark;
  border: 1px solid $color-primary-dark;
  &:hover {
    background: $color-primary-dark;
    border: 1px solid $color-primary-dark;
    color: $color-primary-light;
  }
  &:disabled {
    background: $color-secondary-lightGrey;
  }
}
.red {
  background: $color-accent-red;
  &:hover {
    background: $color-hover-red;
  }
  &:disabled {
    background: $color-accent-red;
    opacity: 50%;
  }
}
.yellow {
  background: $color-accent-yellow;
  &:hover {
    background: $color-hover-yellow;
  }
  &:disabled {
    background: $color-accent-yellow;
    opacity: 50%;
  }
}