@import "../../styles/shared.scss";

.modal {
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  height: 508px;
  background-color: $color-secondary-yellowish;

  @media screen and (min-width: 375px) {
    padding: 102px 25px;
    max-width: 339px;
  }

  @media screen and (min-width: 768px) {
    padding: 102px 30px;
    max-width: 440px;
  }
  @media screen and (min-width: 950px) {
    padding: 102px 75px;
  }
}

.icon {
  margin-bottom: 29px;
}

.title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: $color-primary-dark;
}

.text {
  margin-bottom: 42px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  color: $color-primary-dark;
}
.buttonContainer {
  display: flex;
}
